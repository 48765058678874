import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import socketIO from 'socket.io-client';
import { authReq } from '../../requests';
import { baseURL } from '../../constants';
import {
  crossIcon,
  navbarLogo,
  profileWhite,
  sideBarAppointment,
  sideBarChat,
  sideBarHome,
  sideBarLogout,
  sideBarSettings,
} from '../../assets';
import './leftSideBar.css';
import { collection, onSnapshot, or, query, where } from 'firebase/firestore';
import { fireDB } from '../../firebase';

const loginSet = (kawan_accessToken, kawan_refreshToken) => {
  document.cookie = `kawan_accessToken=${kawan_accessToken};path=/;domain=.kavanhealth.com`;
};

const socket = socketIO(baseURL);

const LeftSideBar = ({ flag, togglefun }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [messageCount, setMessageCount] = useState(0);
  const [optionsArray, setOptionsArray] = useState([
    {
      id: 1,
      title: 'HOME',
      icon: sideBarHome,
      route: '/dashboard/AdminHome',
      to: '/dashboard/AdminHome',
    },
    {
      id: 2,
      title: 'AGENDA',
      icon: sideBarAppointment,
      route: '/dashboard/AppointmentStack',
      to: '/dashboard/AppointmentStack',
    },
    {
      id: 3,
      title: `Chat ${messageCount ? `(${messageCount})` : ''}`,
      icon: sideBarChat,
      route: '/dashboard/ChatStack',
      to: '/dashboard/ChatStack',
      // onClick: () => {
      //   setMessageCount(0)
      //   setOptionsArray(optionsArray.map(x => x.id === 3 ? { ...x, title: "Chat" } : x))
      // }
    },
    {
      id: 4,
      title: 'Pacientes',
      icon: profileWhite,
      route: '/dashboard/PacientsStack',
      to: '/dashboard/PacientsStack',
    },
    {
      id: 5,
      title: 'SETTINGS',
      icon: sideBarSettings,
      route: '/dashboard/SettingStack',
      to: '/dashboard/SettingStack',
    },
  ]);

  const toggleBack = () => {
    if (flag) {
      togglefun();
    }
  };

  useEffect(() => {
    authReq('GET', '/user/stripeLogin')
      .then((data) => {
        const url = data?.link?.url;
        const user = data?.user;
        console.log('SIDEBAR-USER', data);
        setUser(user);
        // console.log(user?.country)
        console.log('DATA LOGIN', data);
        if (data && !optionsArray.find((opt) => opt.title == 'STRIPE'))
          setOptionsArray([
            ...optionsArray,
            user?.country?.toLowerCase() == 'argentina'
              ? {}
              : {
                  id: 6,
                  title: 'STRIPE',
                  icon: '',
                  route: false,
                  link: url,
                  stripe: true,
                },
          ]);
      })
      .catch(() => {
        console.log('error');
        setOptionsArray(optionsArray.filter((x) => !x?.stripe));
      });
    authReq('GET', '/user/me').then((data) => setUser(data.data));
  }, []);

  useEffect(() => {
    if (!user?._id) return;
    const f = async () => {
      const q = query(
        collection(fireDB, 'production_messages'),
        or(
          where('sender._id', '==', user?._id),
          where('receiver._id', '==', user?._id)
        )
      );
      onSnapshot(q, async (snapshot) => {
        const messages = snapshot.docs
          .map((doc) => ({ uid: doc.id }))
          .sort((a, b) =>
            (a?.createdAt ?? a?.messageTime) > (b?.createdAt ?? b?.messageTime)
              ? 1
              : -1
          );
        const users = {};
        const userLastMessages = {};
        const userMessageCounts = {};
        for (const message of messages) {
          userMessageCounts[message?.sender?._id] = 0;
          userMessageCounts[message?.receiver?._id] = 0;
        }
        for (const message of messages) {
          if (message?.sender && !message?.readByDoctor)
            userMessageCounts[message?.sender?._id] += 1;
          if (message?.receiver && !message?.readByDoctor)
            userMessageCounts[message?.receiver?._id] += 1;
          console.log('ibxsaaa0-0', inboxes);
        }
        delete userMessageCounts[user?._id];

        const inboxes = Object.values(users);
        console.log('ibxsaaa0', userMessageCounts);
        setMessageCount(inboxes.length);
      });
    };
    f();
  }, [user]);

  console.log('Rendering the left side bar!!');
  return (
    <div
      className={
        flag
          ? 'kwn-left_side_bar-res-main_container'
          : 'kwn-left_side_bar-main_container'
      }
    >
      <div className="kwn-left-side_bar-logo_container">
        <img
          onClick={() =>
            (window.location.href = 'https://www.kavanhealth.com/')
          }
          src={navbarLogo}
        />
        <div
          onClick={togglefun}
          className="kwn-left-side_bar-logo-cross-icon_container"
        >
          {flag ? <img src={crossIcon} /> : null}
        </div>
      </div>
      <div className="kwn-left-side_bar-options_container">
        {optionsArray
          .filter((item) => item.title)
          .filter((item) => !(!user && item.id == 6))
          .map((item) => {
            return (
              <div
                onClick={() => {
                  if (item.onClick) item.onClick();
                  if (item.to) navigate(item.to);
                  else window.open(item.link, '_blank');
                  toggleBack();
                }}
                className={'kwn-left-side_bar-options_view'}
                style={{
                  backgroundColor: window.location.href.includes(item.route)
                    ? '#1f7753'
                    : '',
                  ...(item.stripe
                    ? {
                        paddingLeft: '0px',
                        background: 'rgb(100, 64, 191)',
                        display: 'flex',
                        margin: '5px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }
                    : {}),
                }}
              >
                {item.icon && <img src={item.icon} />}
                <h2>{!user && item.id == 6 ? '' : item.title}</h2>
              </div>
            );
          })}
      </div>
      <div
        onClick={async () => {
          window.location.replace('https://www.kavanhealth.com/');
          // loginSet('');
          // await authReq('POST', '/user/logout', {
          //   device: { id: 'web', deviceToken: 'MockToken' },
          // });
          // localStorage.clear();
          
        }}
        className="kwn-left-side_bar-logout_container"
      >
        <img src={sideBarLogout} />
        <h2>Logout</h2>
      </div>
    </div>
  );
};

export default LeftSideBar;
