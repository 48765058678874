import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import { authReq } from '../../requests';
import {
  bookingGrey,
  calenderIcon,
  chat,
  chatGrey,
  kavanLogo,
  profile,
  profileGrey,
} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { Loader } from '..';

export const loginSet = (kawan_accessToken) => {
  document.cookie = `kawan_accessToken=${kawan_accessToken};path=/;domain=.kavanhealth.com`;
};

const TopNavbar = ({ active }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      console.log("fetchin user")
      try {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          setUser(JSON.parse(storedUser));
        } else {
          const data = await authReq('GET', '/user/me');
          localStorage.setItem('user', JSON.stringify(data));
          setUser(data);
        }
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    }

    fetchUser();
    setInterval(fetchUser, 1000);
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async () => {
    try {
      setDropdownOpen(false);
      await authReq('POST', '/user/logout', {
        device: { id: 'web', deviceToken: 'MockToken' },
      });
      localStorage.clear();
      loginSet(''); // Ensure this is the intended behavior
      window.location.href = ('https://www.kavanhealth.com/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const dropDownNavigate = (path) => {
    setDropdownOpen(false);
    navigate(path);
  };

  const getUrl = async () => {
    setIsLoading(true);
    try {
      const data = await authReq('GET', '/user/stripeLogin');
      setIsLoading(false);
      const url = data?.link?.url;
      window.location.href = url;
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to get URL:', error);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="kavan__navbar-links_logo">
        <img
          onClick={() => (window.location.href = 'https://www.kavanhealth.com')}
          src={kavanLogo}
          alt="Logo"
        />
      </div>
      <div className="nav-links">
        <div
          onClick={() => {
            navigate('/dashboard/AppointmentStack/');
          }}
          className="menu-box"
        >
          <img
            className="menu-icon"
            src={active === 'calendar' ? calenderIcon : bookingGrey}
            alt="Agenda"
          />
          <a
            style={{ color: active === 'calendar' ? '#006039' : '#9B9B9B' }}
            className="menu-text"
          >
            Calendario
          </a>
        </div>
        <div
          onClick={() => {
            navigate('/dashboard/PacientsStack/');
          }}
          className="menu-box"
        >
          <img
            className="menu-icon"
            src={active === 'Pacients' ? profile : profileGrey}
            alt="Pacientes"
          />
          <a
            style={{ color: active === 'Pacients' ? '#006039' : '#9B9B9B' }}
            className="menu-text"
          >
            Pacientes
          </a>
        </div>
        <div
          onClick={() => {
            navigate('/dashboard/ChatStack/');
          }}
          className="menu-box"
        >
          <img
            className="menu-icon"
            src={active === 'Chat' ? chat : chatGrey}
            alt="Chat"
          />
          <a
            style={{ color: active === 'Chat' ? '#006039' : '#9B9B9B' }}
            className="menu-text"
          >
            Mensajes
          </a>
        </div>
        {user?.data?.country?.toLowerCase() !== 'argentina' && (
          <div
            onClick={() => {
              getUrl();
            }}
            id="stripe"
            className="menu-box"
          >
            <a className="menu-text">Stripe</a>
          </div>
        )}
      </div>

      {/* Hamburger Menu */}
      <div className="hamburger-menu" onClick={toggleMobileMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="profile-icon">
        <img src={user?.data?.image} alt="Profile" onClick={toggleDropdown} />
        {dropdownOpen && (
          <div ref={dropdownRef} className="dropdown-menu">
            <a
              onClick={() => {
                dropDownNavigate('/dashboard/SettingStack/profile');
              }}
            >
              Profile
            </a>
            <a onClick={handleLogout}>Logout</a>
          </div>
        )}
      </div>
      {isLoading && <Loader />}

      {/* Mobile Menu */}
      <div className={`mobile-menu ${mobileMenuOpen ? '' : 'hidden'}`}>
        <a
          onClick={() => {
            navigate('/dashboard/AppointmentStack/');
            setMobileMenuOpen(!mobileMenuOpen);
          }}
        >
          Calendario
        </a>
        <a
          onClick={() => {
            navigate('/dashboard/PacientsStack/');
            setMobileMenuOpen(!mobileMenuOpen);
          }}
        >
          Pacientes
        </a>
        <a
          onClick={() => {
            navigate('/dashboard/ChatStack/');
            setMobileMenuOpen(!mobileMenuOpen);
          }}
        >
          Mensajes
        </a>
        {user?.data?.country?.toLowerCase() !== 'argentina' && (
          <a onClick={() => getUrl()}>Stripe</a>
        )}
      </div>
    </nav>
  );
};

export default TopNavbar;
