import React, { useState, useEffect } from 'react';
import { user, users } from '../../../assets';
import { SearchBar, Loader } from '../../../components';
import { authReq } from '../../../requests';
import { jsPDF } from 'jspdf';
import './pacients.css';
import { fireDB } from '../../../firebase';
import {
  collection,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  and,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getNameShort } from '../../../urlParams';

const processDate = (d) => {
  const time = new Date(d).getTime() - new Date().getTimezoneOffset() * 60_000;
  return new Date(time).toISOString();
};

function random(seed) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

const sdbm = (str) => {
  let arr = str.split('');
  return arr.reduce(
    (hashCode, currentVal) =>
      (hashCode =
        currentVal.charCodeAt(0) +
        (hashCode << 6) +
        (hashCode << 16) -
        hashCode),
    0
  );
};

const characters = 'abcdefghijklmnopqrstuvwxyz';

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const Pacients = (props) => {
  const [patientData, setPatientData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [patientsAppointment, setPatientsAppointment] = useState([]);
  const patientSet = new Set();
  const [searchText, setSearchText] = useState('');
  const [searchData, setSearchData] = useState([]);
  const onSearch = (text) => {
    setSearchText(text);
    setSearchData(
      patientData.filter((x) =>
        x.name.toLowerCase().startsWith(text.toLowerCase())
      )
    );
  };
  const [user, setUser] = useState(null);

  useEffect(() => {
    props.setActive('Pacients');
    if (localStorage.getItem('user'))
      setUser(JSON.parse(localStorage.getItem('user')));
    else
      authReq('GET', '/user/me').then((data) => {
        localStorage.setItem('user', JSON.stringify(data));
        setUser(data);
      });
  }, []);

  useEffect(() => {
    console.log('next stppppp', user?.data?._id);
    if (!user?.data?._id) return;

    const setPatients = (data) => {
      console.log('doooootooooo', data);
      const patientDict = {};
      for (const appointment of data.appointments) {
        if (patientDict[appointment?.appointer?._id])
          patientDict[appointment?.appointer?._id].push(appointment);
        else patientDict[appointment?.appointer?._id] = [appointment];
      }
      for (const k in patientDict) {
        const v = patientDict[k];
        patientDict[k] = v.sort((a, b) =>
          a.appointmentStart > b.appointmentStart ? 1 : -1
        );
      }
      console.log('Patients Object', patientDict);
      setPatientData(
        data?.appointments
          ?.filter((x) => x?.appointer?._id)
          .map((app) => {
            let seed = sdbm(app.appointer._id);
            const arr = new Array(8)
              .fill(0)
              .map((_) => Math.round(random(Math.abs(seed++)) * 10));
            console.log(
              patientDict[app.appointer._id][0],
              patientDict[app.appointer._id][1]
            );
            return {
              id: app._id,
              userInfo: app.appointer?.userInfo,
              name: app.appointer.name,
              patientId: app.appointer._id,
              image: app.appointer.image,
              lastVisit: `${new Date(
                patientDict[app.appointer._id]?.[0]?.appointmentStart
              ).toISOString()}`,
              nextVisit: `${
                patientDict[app.appointer._id][1]
                  ? new Date(
                      patientDict[app.appointer._id][1].appointmentStart
                    ).toISOString()
                  : ''
              }`,
              idNumber: `${arr.join('')}${characters
                .charAt(
                  Math.floor(
                    random(sdbm(app.appointer._id)) * characters.length
                  )
                )
                .toUpperCase()}`,
            };
          })
          .filter((x) => {
            console.log(patientSet, x?.idNumber);
            if (patientSet.has(x?.idNumber)) return false;
            patientSet.add(x?.idNumber);
            return true;
          })
      );
    };

    const noQuotes = objs => {
      const nobjs = []
      for(const obj of objs) {
        nobjs.push({
          ...obj,
          appointer: {
            ...obj.appointer,
            name: obj.appointer?.name?.split?.('"')?.join?.('')
          }
        })
      }

      return nobjs
    }

    const f = async () => {
      console.log('___ aaaa');
      const q = query(
        collection(fireDB, 'appointments'),
        where('appointee._id', '==', user?.data?._id)
      );
      console.log('___ bbbb');
      const querySnapshot = await getDocs(q);
      //const appointments = querySnapshot.docs.map(doc => doc.data());
      //console.log("___ ccc", appointments)
      // querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      // setPatients({ appointments })
      //console.log("___ ddd", appointments)
      const unsub = onSnapshot(q, (querySnapshot) => {
        const appointments = querySnapshot.docs.map((doc) => doc.data());
        setPatients({ appointments: noQuotes(appointments) });
      });

      setLoading(false);
      return unsub;
    };

    const unsub = f();
    // return () => unsub()s
  }, [user]);

  const viewClick = async (item) => {
    // let appointmentQuery = query(collection(fireDB, "appointments"), and(
    //   where("appointee._id", "==", user?.data?._id),
    //   where('appointer._id', '==', item?.patientId),
    // ));
    // const appointments = (await getDocs(appointmentQuery)).docs
    // setPatientsAppointment(appointments.map(doc => doc.data()))
    // setModalVisible(true)

    // setActiveData(item)
    // setModalVisible(true)
    navigate(`/dashboard/details?${item?.patientId}`);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('user-modal-overlay')) {
      setModalVisible(false);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setPatientsAppointment([]);
  };

  const getSpanishStatus = (status) => {
    if (status == 'patient-pending') {
      return 'Pendiente del paciente';
    }
    if (status == 'patient-reschedule') {
      return 'reprogramar por paciente';
    }
    if (status == 'patient-upcoming') {
      return 'Paciente aceptado';
    }
    if (status == 'patient-completed') {
      return 'Paciente completado';
    }
    if (status == 'patient-canceled') {
      return 'Paciente cancelado';
    }
    return 'Otro';
  };

  const getRefundStatus = (status) => {
    if (status == 'not-refunded') {
      return 'No reembolsado';
    }
    return 'Reintegrado';
  };

  return (
    <div>
      {loading && <Loader />}
      <div
        style={{ paddingLeft: '15%', paddingRight: '15%', overflowX: 'hidden' }}
        className="kavan_admin_main_container"
      >
        <div className="kwn-pacients-count-container">
          <h1 style={{ color: '#022D2E' }} className="kwn-heading">
            pacientes ({patientData?.length})
          </h1>
        </div>
        <SearchBar
          visible={true}
          setSearchText={onSearch}
          searchText={searchText}
          placeholder={'Buscar un paciente...'}
        />

        <div
          style={{ alignSelf: 'center', marginTop: 18 }}
          className="kwn-pacients-table-view"
        >
          <div className="user-list">
            {(searchText != '' ? searchData : patientData).map(
              (item, index) => (
                <div
                  onClick={() => {
                    viewClick(item);
                  }}
                  className="user-card"
                >
                  {false ? (
                    <img
                      src={item?.image && item.image != '' ? item.image : ''}
                      alt={`profile`}
                      className="user-image"
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: `#${item?.patientId?.slice(0, 6)}`,
                      }}
                      className="user-image"
                    >
                      <p className="short-name">
                        {item?.name ? getNameShort(item?.name) : ''}
                      </p>
                    </div>
                  )}
                  <div className="user-details">
                    <p className="user-name-2">
                      {item?.name?.slice(0, 10)}
                      {item?.name?.length > 10 && '..'}
                    </p>
                    {/* <p className="user-dob"><strong>Última cita:</strong> {item?.lastVisit.slice(0, 10)}</p>
                <button onClick={() => { viewClick(item) }} className="view-details-button" >Ver citas</button> */}
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        {modalVisible && (
          <div className="user-modal-overlay" onClick={handleOverlayClick}>
            <div className="user-modal" onClick={(e) => e.stopPropagation()}>
              {patientsAppointment.length > 0 && (
                <div>
                  <img
                    src={patientsAppointment[0].appointer?.image}
                    alt={`${patientsAppointment[0].appointer?.name}'s profile`}
                    className="user-modal-appointee-image"
                  />
                  <h2 className="user-modal-name">{`Citas con ${patientsAppointment[0].appointer?.name}`}</h2>
                </div>
              )}
              <div className="model-scroll">
                <table className="user-modal-table">
                  <thead>
                    <tr>
                      <th className="table-label">Inicio de la cita</th>
                      <th className="table-label">Fin de la cita</th>
                      <th className="table-label">Estado</th>
                      <th className="table-label">
                        Estado de reembolso manual
                      </th>
                      <th className="table-label">Razon de cancelacion</th>
                      <th className="table-label">Ranura</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patientsAppointment.map((appointment, index) => (
                      <tr key={index}>
                        <td className="table-value">
                          {new Date(
                            appointment?.appointmentStart
                          ).toLocaleString()}
                        </td>
                        <td className="table-value">
                          {new Date(
                            appointment?.appointmentEnd
                          ).toLocaleString()}
                        </td>
                        <td className="table-value">
                          {getSpanishStatus(appointment?.status)}
                        </td>
                        <td className="table-value">
                          {getRefundStatus(appointment?.manualRefundStatus)}
                        </td>
                        <td className="table-value">
                          {appointment?.cancelationReason || 'N/A'}
                        </td>
                        <td className="table-value">{appointment?.slot}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <button className="user-modal-close" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pacients;
