// src/components/ChatInput.js
import React, { useState } from 'react';
import './ChatInput.css';
import { mic, send } from '../../../../assets';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { authReq, uploadAudioOnS3 } from '../../../../requests';

const ChatInput = ({ onSend }) => {
  const [input, setInput] = useState('');
  const [currAudio, setCurrAudio] = useState('');
  const [isRecording, setIsRecording] = useState(false);

  const addAudioElement = (blob) => {
    console.log('recording sent');
    uploadAudioOnS3(blob).then((url) => {
      setCurrAudio(url);
      setIsRecording(false);
      onSend(url, true);
      setInput('');
      setCurrAudio(null)
      // Reset recording state after uploading
    });
  };

  const sendMessage = (e) => {
    onSend(input, false);
    setInput('');
  };

  return (
    <div className="inputContainer">
      <div className="chat-input">
        {/* <form> */}
        {!currAudio && <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Escribe tu mensaje aquí"
          type="text"
          onKeyUp={(ev) => ev.key == 'Enter' && sendMessage()}
        />}

        {/* </form> */}
      </div>

      <AudioRecorder
        onRecordingComplete={addAudioElement}
        onStartRecording={() => {
          setIsRecording(true);
          console.log('Recording started');
        }} // Set recording to true
        audioTrackConstraints={{
          noiseSuppression: true,
          echoCancellation: true,
        }}
        onNotAllowedOrFound={(err) => {
          console.table(err);
          setIsRecording(false); // Reset if there is an error
        }}
        downloadOnSavePress={true}
        downloadFileExtension="webm"
        mediaRecorderOptions={{
          audioBitsPerSecond: 128000,
        }}
        showVisualizer={true}
      />

      <img
        id="send-message"
        className="send-message-icon"
        src={send}
        style={{ cursor: 'pointer' }}
        onClick={() => sendMessage()}
      />
    </div>
  );
};

export default ChatInput;
