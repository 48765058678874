// src/components/Chat.js
import React, { useState, useEffect, useRef } from 'react';
import ChatHeader from './chatHeader';
import ChatMessages from './chatMessages';
import ChatInput from './chatInput';
import Sidebar from './sideBar';
import './Chat.css';


import { authReq, uploadAudioOnS3 } from "../../../requests";

import { useNavigate } from "react-router-dom";
import { AudioRecorder } from "react-audio-voice-recorder";
import { addDoc, collection, doc, getDocs, onSnapshot, or, query, updateDoc, where } from "firebase/firestore";
import { fireDB } from "../../../firebase";
import { Loader } from '../../../components';
import { chatLogo, search } from '../../../assets';
import { getNameShort } from '../../../urlParams';


const Chat = (props) => {

    const [currentChat, setCurrentChat] = useState(null);

    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    const [currAudio, setCurrAudio] = useState("")

    const [messages, setMessages] = useState([])
    const [inboxes, setInboxes] = useState([])
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const counter = useRef(0)





    const addAudioElement = (blob) => uploadAudioOnS3(blob).then(url => setCurrAudio(url))

    useEffect(() => {
        props.setActive("Chat")
        authReq("GET", "/user/me").then((data) => setUser(data.data));
    }, []);

    useEffect(() => {
        if (!user?._id) return;
        const f = async () => {
            const q = query(collection(fireDB, 'messages'), or(
                where('sender._id', '==', user?._id),
                where('receiver._id', '==', user?._id),
            ))

            onSnapshot(q, async snapshot => {
                const messages = snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })).sort((a, b) => (a?.createdAt ?? a?.messageTime) > (b?.createdAt ?? b?.messageTime) ? -1 : 1)
                const selectedChatMessages = messages.filter(x => x?.sender?._id == currentChat?._id || x?.receiver?._id == currentChat?._id)
                for (const message of selectedChatMessages) {

                    const ref = doc(fireDB, 'messages', message.uid)
                    updateDoc(ref, { readByDoctor: true })
                }

                setMessages(messages)
                const users = {}
                const userLastMessages = {}
                const userMessageCounts = {}
                for (const message of messages) {
                    userMessageCounts[message?.sender?._id] = 0
                    userMessageCounts[message?.receiver?._id] = 0
                }
                for (const message of messages) {
                    if (message?.sender) users[message?.sender?._id] = message?.sender
                    if (message?.receiver) users[message?.receiver?._id] = message?.receiver
                    if (message?.sender && (message?.createdAt || message?.messageTime)) userLastMessages[message?.sender?._id] = message
                    if (message?.receiver&& (message?.createdAt || message?.messageTime)) userLastMessages[message?.receiver?._id] = message
                    if (message?.sender && !message?.readByDoctor) userMessageCounts[message?.sender?._id] += 1
                    if (message?.receiver && !message?.readByDoctor) userMessageCounts[message?.receiver?._id] += 1
                }
                delete users[user?._id]
                delete userLastMessages[user?._id]
                delete userMessageCounts[user?._id]

                const inboxes = Object.values(users).map(u => ({
                    ...u,
                    unreadMessageCount: currentChat?._id == u?._id ? 0 : userMessageCounts?.[u?._id],
                    message: userLastMessages[u?._id]?.message,
                    lastMessageTime: userLastMessages[u?._id]?.createdAt ?? userLastMessages[u?._id]?.messageTime,
                }))
                setInboxes(inboxes)
                if (counter.current == 0) setCurrentChat(inboxes.length > 0 ? inboxes[0] : null)
                setLoading(false)
                setTimeout(() => scrollToLastMessage(), 1000);
                counter.current++
            })
        }
        f()
    }, [user])

    useEffect(() => {
        if (!currentChat?._id) return;
        const selectedChatMessages = messages.filter(x => x?.sender?._id == currentChat?._id || x?.receiver?._id == currentChat?._id)
        for (const message of selectedChatMessages) {
            const ref = doc(fireDB, 'messages', message.uid)
            updateDoc(ref, { readByDoctor: true })
        }
        scrollToLastMessage()
    }, [currentChat])

    const sendMessage = async (message) => {
        if (!message) return

        // Send a message

        const el = document.getElementById(currentChat.id)
        if (el) el.innerHTML = message

        const obj = {
            createdAt: new Date().getTime(),
            message: currAudio ? currAudio : message,
            messageTime: new Date().getTime(),
            receiver: {
                _id: currentChat?._id,
                name: currentChat?.name,
                image: currentChat?.image,
            },
            readByDoctor: true,
            sender: {
                _id: user?._id,
                name: user?.name,
                image: user?.image
            },
            messageType: currAudio ? "audio" : "text",
            updatedAt: "2023-05-05T12:34:36.748Z",
            // _id: `${Math.round(Math.random() * 10000)}`,
        }
        setMessages([
            ...messages,
            obj,
        ]);

        setCurrAudio("")

        await addDoc(collection(fireDB, 'messages'), obj)
        setTimeout(() => scrollToLastMessage(), 700);
    };

    const scrollToLastMessage = () => {
        const messageEls = document.getElementsByClassName("chat-message-container")
        messageEls[messageEls.length - 1]?.scrollIntoView()
    }





    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredChats = inboxes.sort((a, b) => (a?.createdAt ?? a?.lastMessageTime) > (b?.createdAt ?? b.lastMessageTime) ? -1 : 1).filter(chat =>
        chat?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const formatDate = date => {
        return `${date.getDate()}/${date.getMonth() + 1}/${`${date.getFullYear()}`.at(-2)}${`${date.getFullYear()}`.at(-1)} ${date.getHours()}:${date.getMinutes()}`
    }


    return (
        <div style={{ paddingLeft: "3%", paddingRight: "3%", backgroundColor: "white" }} >
            <div style={{ backgroundColor: "white", paddingBottom: 18 }} className="kwn-pacients-count-container">
                <h1 style={{ color: "#022D2E" }} className="kwn-heading">Mensajes</h1>
            </div>

            <div className="chat-app">
                {
                    inboxes.length > 0 &&
                    <>
                        <div className="sidebar">
                            <div className="sidebar-search">
                                <img src={search} alt="Search" className="search-icon" />
                                <input
                                    type="text"
                                    placeholder="Buscar un chat..."
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            {filteredChats.map((chat, index) => (
                                <div style={{ marginTop: index == 0 ? 60 : undefined, backgroundColor: currentChat?._id == chat?._id ? "#F0F0F0" : "#ffffff" }} key={index} className="sidebar-chat" onClick={() => setCurrentChat(chat)}>
                                    <div style={{ backgroundColor: `#${chat?._id?.slice(0, 6)}` }} className="user-image" >
                                        <p className="short-name" >{getNameShort(chat?.name)}</p>
                                    </div>
                                    <div className="sidebar-chat-info">
                                        <h4>{chat?.name}</h4>
                                        <p>{chat?.message?.slice(0, 30)}{chat?.message?.length > 30 ? "..." : ""}</p>
                                    </div>
                                </div>
                            ))}
                        </div>


                        <div className="chat">
                            <ChatHeader user={user} currentChat={currentChat} />
                            {
                                messages.length > 0 &&
                                <div className="chat-messages">
                                    {messages.sort((a, b) => ((a.messageTime ?? a.createdAt) > (b.createdAt ?? b.createdAt) ? 1 : -1)).filter(x => x?.sender?._id == currentChat?._id || x?.receiver?._id == currentChat?._id).map((item, index) => (
                                        <div key={index} className="chat-message-container" style={{ width: "100%" }}>
                                            <div className='chat-bubble' >
                                                {item?.sender?._id != user._id && (
                                                    <div style={{ backgroundColor: `#${item?.patientId?.slice(0, 6)}` }} className="user-image" >
                                                        <p className="short-name" >{item?.name ? getNameShort(item?.name) : ''}</p>
                                                    </div>
                                                )}
                                                <div className={`chat-message ${item?.sender?._id == user._id ? 'sent' : 'received'}`}>
                                                    {item?.messageType == 'audio' || item?.message?.endsWith?.('.webm') || item?.message?.endsWith?.('.mp3') ? <audio controls>
                                                        <source src={item?.message} type="audio/webm" />
                                                    </audio> : <p className='chat-message-text'>{item?.message}</p>}
                                                </div>
                                            </div>
                                            <span className={`chat-timestamp ${item?.sender?._id == user._id ? 'sent' : 'received'}`}>
                                                {formatDate(new Date(item.createdAt))}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            }

                            <ChatInput onSend={sendMessage} />
                        </div>
                    </>

                }

            </div>
            {loading && <Loader />}

        </div>
    );
};

export default Chat;