import React from "react";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import AppDownload from "../../../components/appDownload/appDownload";
import "./contactUs.css";
import FooterPublic from "../../../components/footerPublic/footerPublic";

const isMobile = {
  Android: function() {
      return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

export default function ContactUs() {

  // window.location.href = "https://www.kavanhealth.com/contactanos"
  // return <></>
  const meetingNumber = window.location.href.split('?meetingNumber=')[1]

  if(isMobile.Android() || isMobile.iOS()) return window.location.href = ` https://appsflyer.kavanhealth.com/YkgZ/zyu8fdp7?sessionLink=https://kavan-video-call.vercel.app/?meetingNumber=${meetingNumber}`
  else return window.location.href = `https://kavan-video-call.vercel.app/?meetingNumber=${meetingNumber}&userName=user&redirect=https://kavanhealth.com` 

  return (
    <div></div>
  );
  
}
