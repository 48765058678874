import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./chooseProfile.css";

import { Button, Footer, Navbar } from "../../../components";
import { getAllParams, setParam } from "../../../urlParams";
import useSaveData from "../../../hooks/useSaveData";

const category = [
  {
    text: "ESPAÑOL",
    id: 1,
  },
  {
    text: "ENGLISH",
    id: 2,
  },
];

const ChooseProfile = () => {
  useSaveData()
  const navigate = useNavigate();
  const [select, setSelect] = useState({
    text: "ENGLISH",
  });

  const toNavigate = () => {
    // setParam({ doctorType: "psychologist", language: select?.text })
    navigate(`/chatbotPro?${setParam({ doctorType: "psychologist", language: select?.text })}`);

    return
    // if (select.text == "Psicólogo") {
    //   navigate(`/psychologist?${setParam({ doctorType: "psychologist" })}`);
    // } else {
    //   navigate(`/coach?${setParam({ doctorType: "coach" })}`);
    // }
  };
  return (
    <div>
      <Navbar />
      <div className="kwn-fill_out_form-main_container">
        <div className="kwn-fill_out_form-title_container">
          <h1>Please select your language / Por favor, selecciona tu idioma</h1>
          <h2>What's your preferred language? / ¿En qué idioma prefieres realizar las sesiones?</h2>
        </div>
        {category.map((item) => (
          <div
            style={{
              background: select.text === item.text ? "#006039" : "#fff",
              border: select.text == item.text ? "none" : "1px solid #006039",
            }}
            className="kavan-select-specialty-container"
            onClick={() => {
              setSelect(item);
            }}
          >
            <h3
              style={{
                color: select.text === item.text ? "#fff" : "#2D292A",
                paddingTop: 0,
              }}
            >
              {item.text}
            </h3>
          </div>
        ))}
        <div className="choose-profile-btn-container">
          <Button onClick={toNavigate}>Siguiente</Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChooseProfile;
